import moment from 'moment'
import 'moment-timezone'
import 'eonasdan-bootstrap-datetimepicker'

var inputFieldChanged;

/**
 * A module for handling methods relating to an inline datetimepicker which is inside a wrapper
 * and behaves somewhat like a modal.
 * Note: To see documentation for the datetimepicker used visit: http://eonasdan.github.io/bootstrap-datetimepicker
 *       For documentation of moment.js visit: http://momentjs.com/docs
 * @author Joseph Krump
 * @param  {Jquery} $)
 * @return {Object} - An object containing any of the publicly accessible methods of this module.
 */
const _file = 'DatePickerWrapper';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var DatePickerWrapper = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    // Add PST timezone
    // moment.tz.add('PST|PST PDT|80 70|0101|1Lzm0 1zb0 Op0');

    $(element).find('.datepicker-opener').each(function () {
      initDatePicker($(this));
    });

    // A separate (simple) time picker that is optionally used within the datepicker
    $(element).find('input.format-as-time').change(function (e) {
      inputFieldChanged(this);
    });

    $(element).find('.add-end-date').click(function(){
      var $endInput = $(element).find($(this).data('datepicker-input-selector'));
      var $endPicker = $(element).find('.datepicker-opener[data-io-selector="' + $(this).data('datepicker-input-selector') + '"]');
      var startVal = $(element).find($endPicker.data('start-io-selector')).val();
      var $removeEndBtn = $(element).find('.remove-end-date[data-datepicker-input-selector="' + $(this).data('datepicker-input-selector') + '"]');

      if (startVal) {
        $endPicker.data('initial-date', moment(startVal).add(1, 'h').toISOString());
        $endPicker.unbind();
        resetForReuse($endPicker.parent().find('.datepicker-wrapper'));
        $(document).trigger('content-ready', $endPicker.parent());

        // Ideally we could just set the value of the end date input, and the button click
        //   would set the (new) datepicker value without needing to re-init everything else
        // $endInput.val(moment(startVal).add(1, 'h').toISOString());
        // Or, just set the datepicker value using the helper function like this
        //   (but we need to keep all the timezone logic for this to work)
        // $endPicker.parent().find('.datepicker-wrapper').find('.inline-dp-root').data('DateTimePicker').date(moment(startVal).add(1, 'h'));
      }
      $endPicker.removeClass('d-none').click();
      $removeEndBtn.removeClass('d-none');
      $(this).addClass('d-none');
    });

    $(element).find('.remove-end-date').click(function(){
      var $endInput = $(element).find($(this).data('datepicker-input-selector'));
      var $endPicker = $(element).find('.datepicker-opener[data-io-selector="' + $(this).data('datepicker-input-selector') + '"]');
      var $addEndBtn = $(element).find('.add-end-date[data-datepicker-input-selector="' + $(this).data('datepicker-input-selector') + '"]');

      $endInput.val('');
      $endPicker.find('.update-date').html($endPicker.data('placeholder-text') || "Choose Date");

      $endPicker.addClass('d-none');
      $addEndBtn.removeClass('d-none');
      $(this).addClass('d-none');
    });
  });

  /**
   * Create a single datepicker to be used in a modal
   * @return {undefined}
   */
  var initDatePicker = function($btn) {
    var $wrapper = $btn.data('datepicker-selector') ? $($btn.data('datepicker-selector')) : $btn.parent().find('.datepicker-wrapper');
    var $dpElement = $wrapper.find('.inline-dp-root');
    var $ioElem = $($btn.data('io-selector'));
    var dateOnly = $btn.hasClass('date-only');
    var btnFormat = $btn.data('btn-format') || ('MMM. D, YYYY' + (dateOnly ? '' : ' h:mm A'));
    var useBrowserTimezone = $btn.hasClass('use-browser-timezone');
    var timezone_identifier = $btn.data('timezone-identifier'); // this is necessary for the date to be converted to the correct timezone
    var timezone_display = $btn.data('timezone'); // this is only for displaying a string different from the timezone abbreviation
    var configInitialDate = $btn.data('initial-date');
    var configViewMode = $btn.data('view-mode');
    // callback = $btn.data('on-date-change'); // needs to happen later for setting it late

    var currDate = new Date();

    var dpOptions = {
      format: 'MM/DD/YYYY',
      inline: true,
      sideBySide: true, // set to false if you want to time picker to be accessible in toolbar.
      viewMode: configViewMode,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(currDate.getFullYear() + 50, currDate.getMonth(), currDate.getDate())
    };

    if ($btn.data('disabled-weekdays')) { dpOptions['daysOfWeekDisabled'] = $btn.data('disabled-weekdays'); }
    if ($btn.data('view-mode')        ) { dpOptions['viewMode']           = $btn.data('view-mode');         }

    dpOptions['icons'] = {
      time: 'fa fa-clock icon icon-clock',
      date: 'fa fa-calendar icon icon-calendar',
      up: 'fa fa-up icon icon-arrow-up',
      down: 'fa fa-down icon icon-arrow-down',
      previous: 'fa fa-left icon icon-left',
      next: 'fa fa-right icon icon-right',
      today: 'fa fa-crosshair',
      clear: 'fa fa-trash icon icon-trash',
      close: 'fa fa-remove icon icon-close'
    };

    var $timeField = $wrapper.find('.time_field');
    dateOnly ? $timeField.addClass('d-none') : $timeField.removeClass('d-none');

    var $dp;

    if($dpElement.length === 0){
      console.warn("Datepicker error - root node not found");
      return null;
    }

    $dpElement.datetimepicker(dpOptions);

    $dp = $dpElement.data('DateTimePicker');

    var dateOrTimeChanged = function() {
      if (timezone_identifier) {
        var date_in_local = $dp.date().tz(moment.tz.guess());
        $wrapper.find('.localtime-display').html(date_in_local.format('h:mm A z'));
        $wrapper.find('.localdate-display').html(date_in_local.format('YYYY-MM-DD'));
      }
    }

    /**
     * Handles the datepicker's value changing.
     * @param  {Event} e - the dp.change event. Contains date and oldDate
     * @return {undefined}
     */
    var dpDateChanged = function(e) {
      var format      = e.data.format;
      var $inputField = $wrapper.find('input.' + (format === 'LT' ? 'time' : 'date') + '-only');

      $inputField.val(e.date.format(format));

      dateOrTimeChanged();
    };

    $dpElement.on('dp.change', {format: 'YYYY-MM-DD'}, dpDateChanged);
    $dpElement.on('dp.change', {format: 'LT'},         dpDateChanged);

    // Prefer an already saved date ($ioElem), then a configured one, the wrapper is a last resort (for the timezone)
    var initialDateStr = $ioElem.val() ? $ioElem.val() : (configInitialDate ? configInitialDate : $wrapper.data('initial-date'));
    var localzone = initialDateStr.match(new RegExp('^(.+)#LOCALZONE$'));
    if (localzone) { initialDateStr = localzone[1];}
    var zone_str = null;
    var initialDate = moment(initialDateStr);

    if (useBrowserTimezone) {
      if (localzone) { // Adjust the time: TREAT AS IF this time was in the browser's timezone
        initialDate.add(moment().utcOffset(initialDateStr).utcOffset() - initialDate.utcOffset(), 'm');
      }
      var matches = new Date().toString().match(new RegExp('(\\(.+?\\))$'));
      if (matches) {
        zone_str = matches[1];
      }
    }
    else if (timezone_identifier) {
      initialDate = initialDate.tz(timezone_identifier);
      zone_str = timezone_display ? timezone_display : moment.tz(timezone_identifier).zoneAbbr();
    }

    if (zone_str == 'UTC' || !zone_str) {
      initialDate = initialDate.utc();
    }

    $dp.date(initialDate);
    $wrapper.find('.cur-time-zone').html(zone_str ? ('(' + zone_str + ')') : '');

    /**
     * Toggles the visibility of the dp
     * @param  {Event} e - The click event on the button that toggled the visibility
     * @return undefined
     */
    var toggleVisibility = function(e) {
      e.preventDefault();

      $btn.addClass('toggled');
      $wrapper.toggleClass('active');

      if (!$wrapper.hasClass('active') && $dp !== undefined) {
        saveDate($(this).hasClass('clear') ? null : $dp.date());
      }
    };

    /**
     * Save the chosen datetime - the datepicker is closing
     * @return undefined
     */
    var saveDate = function (date) {
      if (btnFormat != 'manual') {
        var $to_replace = $btn.find('.update-date');
        if ($to_replace.length == 0) {
          $to_replace = $btn;
        }
        var btn_txt = $btn.data('placeholder-text') || "Choose Date";
        if (date) {
          btn_txt = date.format(btnFormat);
          if (timezone_identifier) {
            btn_txt += ' ' + moment.tz(timezone_identifier).zoneAbbr();
          } else if (timezone_display) {
            btn_txt += ' ' + timezone_display;
          }
        }
        $to_replace.html(btn_txt);
      }

      $ioElem.val(!date ? '' : (dateOnly ? date.format('YYYY-MM-DD') : date.toISOString()));

      // after the ioElem is updated so that it can be used or submitted
      var callback = $btn.data('on-date-change');
      if (callback) {
        callback(date);
      }
    };

    // When either the close button or the button that opens the datepicker are clicked,
    // Toggle the visibility of the corresponding datetimepicker.
    //
    $wrapper.find('.close-dp').click(toggleVisibility);
    $btn.click(toggleVisibility);

    // NOTE: TEMPORARILY REMOVED. THIS WOULD SWITCH BETWEEN THE DATEPICKER AND THE TIME
    //       PICKER DEPENDING ON WHAT INPUT IS IN FOCUS - JK
    // $('.datepicker-fields input[type=text]').focus(function(e){
    //   e.preventDefault();

    //   if($dp.format() === 'MM/DD/YYYY' && $(this).hasClass('format-as-time')){
    //     $dp.hide().format('LT').show();
    //   } else if($dp.format() === 'LT' && $(this).hasClass('date-only')) {
    //     $dp.hide().format('MM/DD/YYYY').show();
    //   }
    // });

    $wrapper.find('input.date-only').change(function(e){
      inputFieldChanged(this);
    });

    if ($ioElem.val() && $dp !== undefined) {
      saveDate($dp.date()); // set the initial date format and timezone with JS (its impossible to get it right on the server)
    }

    /**
     * [inputFieldChanged description]
     * @param  {[type]} inputField [description]
     * @return {[type]}            [description]
     */
    inputFieldChanged = function(inputField) {

      var $inputField = $(inputField);
      var inputfieldFormat = $inputField.hasClass('format-as-time') ? 'LT' : 'MM/DD/YYYY';

      // get the number of integers in the string.
      var intsCount = $inputField.val().replace(/[^0-9]/g,"").length;
      var originalFormat = inputfieldFormat;
      // The format used for Time as 'HH:MM am/pm' is LT
      var isTime = originalFormat === 'LT' ? true : false;

      inputfieldFormat = setDateFormat(inputfieldFormat, intsCount);
      var newMomentObject = moment($inputField.val(), inputfieldFormat);

      var $wrapper = $inputField.parents('.datepicker-wrapper');
      var $dp = ($wrapper.length > 0) ? $wrapper.find('.inline-dp-root').data('DateTimePicker') : undefined;

      // Based on whether the momentObject is valid or not (using moment.js .isValid()), add, or remove the 'has-error'
      // class and change the value in the input field and for the datetimepicker.
      if(newMomentObject.isValid()){
        if($dp !== undefined) {
          setDateTimePickerDateTime($dp, isTime, newMomentObject);
        }
        // If there were any error classes added to this input field's parent  then remove them.
        $inputField.parent().removeClass('has-error');
        // Set the input field's value to the formatted value.
        $inputField.val(newMomentObject.format(originalFormat));
      } else {

        resetToDefault($inputField, $dp);
        // If validation failed then add the 'has-error' class to the input field's parent
        // Note: 'has-error' is a bootstrap class.
        $inputField.parent().addClass('has-error');
      }
    };

    /**
     * Method for resetting a specific part of the datepicker to it's default value
     * @param {Object} $inputField - The specific field (date or time) to reset
     * @param {DatePicker} $dp     - The current datetimepicker
     */
    function resetToDefault($inputField, $dp){
      var inputfieldFormat = $inputField.hasClass('format-as-time') ? 'LT' : 'MM/DD/YYYY';
      var isTime = inputfieldFormat === 'LT' ? true : false;
      var newMoment;

      if(isTime){
        $inputField.val(initialDate.format('H:mm A'));
        setDateTimePickerDateTime($dp, isTime, initialDate);
      } else {
        $inputField.val(initialDate.format(inputfieldFormat));
        setDateTimePickerDateTime($dp, isTime, initialDate);
      }
    }

    function setDateTimePickerDateTime($dp, isTime, newMomentObject){
      if($dp !== undefined){
        var currentDate = $dp.date();

        // Set the appropriate values to update the current moment object that is used to keep track of
        // the datetimepicker widget's datetime.
        if(isTime){
          currentDate.hour(newMomentObject.hour()).minute(newMomentObject.minute());
        } else {
          currentDate.year(newMomentObject.year()).month(newMomentObject.month()).date(newMomentObject.date());
        }

        dateOrTimeChanged();

        // Set the datetimepicker to be the moment that was set.
        $dp.date(currentDate);
        return currentDate;
      }
    }

    /**
     * Sets the correct datepicker format to use based on the number of
     * ints in the string that was entered.
     * @param {string} inputfieldFormat - The string that was entered into a date text field.
     * @param {int} intsCount - The number of integers persent in the date string that was entered.
     * @return {string} - a string containing the date format to use.
     */
    function setDateFormat(inputfieldFormat, intsCount){
      if(inputfieldFormat === 'MM/DD/YYYY' && intsCount <= 7){
        inputfieldFormat = 'MM/DD/YY';
      }
      return inputfieldFormat;
    }
  };

  /**
   * Resets the values of the dp's input fields while
   * still preserving the date store for the dp so if
   * opened again, the user can continue where they left off.
   * @param  {Object} $wrapper - The DOM element that contains the datepicker wrapper
   * @return undefined
   */
  function resetDP($btn){
    var $wrapper = $btn.parent().find('.datepicker-wrapper');
    $wrapper.find('input[type=text]').each(function(){
      $(this).val(''); // reset the value in the input fields
    });

    var $ioElem  = $($btn.data('io-selector'));
    $ioElem.val('');
  }


  function resetForReuse($wrapper) {
    $wrapper.removeClass('active');
    $wrapper.find('.close-dp').unbind();
    $wrapper.find('input').unbind();
  }


  // Return API for other modules
  return {
    resetForReuse: resetForReuse,
    reset: resetDP
  };
})(jQuery);

export default DatePickerWrapper